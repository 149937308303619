import React, { useEffect, useState } from 'react';
import { onSnapshot, collection, query, where, limit } from 'firebase/firestore'
import { db } from '../../../system/firebase/index'
import { Box, Text } from 'rebass/styled-components'
import Layout from '../../../templates/Main'
import Gallery from '../../../modules/Gallery'
import About from '../../../modules/About'
import Brandmark from '../../../assets/icons/brandmark-transparent.svg'


const RestorersPage = ({ params }) => {
    const [restorer, setRestorer] = useState([])
    useEffect(() => {
        const q = query(collection(db, 'restorers'), where('slug', '==', params.restorer), limit(2));
        onSnapshot( q, (snapshot) => {
            const output = []
            snapshot.docs.map((doc) => {
                output.push(doc.data())
            })
            setRestorer(output[0])
        })
    }, [])
    const restorerName = restorer && restorer.name ? restorer.name.split(' ')[0] : null
    return (
        <Layout id={params.id}>
            <Box bg='charcoal' width='100%'>
                <Box variant ='container'>
                    <Gallery data={restorer} />
                </Box>
            </Box>
            <Box sx={{ position: 'relative' }}>
                <Box sx={{ position: 'absolute', top: ['-110px', '-110px', '-110px', '-110px', '-140px']}} />
                <Text variant='largeBg' sx={{ textTransform: 'uppercase' }}>
                    {restorerName}
                </Text>
                <Box width='385px' sx={{ zIndex: '1', position: 'absolute' }}>
                    <Brandmark />
                </Box>
            </Box>
            <Box variant ='container'>
                <Box sx={{ position: 'relative', zIndex: '10', top: ['-110px', '-110px', '-110px', '-110px', '-140px']}}>
                    <About data={restorer} />
                </Box>
            </Box>
        </Layout>
    )
}

export default RestorersPage
